import axiosService from "src/api/axiosService";

class ExternalItemService {
  create(data) {
    return axiosService.post(`/api/externalitem/create`, data);
  }

  getById(id) {
    return axiosService.get(`/api/externalitem/getbyid/${id}`);
  }

  getAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key]}&`;
      });
    }
    return axiosService.get(`/api/externalitem/all/${pageNo}?${params}`);
  }

  updateStatus(data) {
    return axiosService.put(`/api/externalitem/updatestatus`, data);
  }

  updateNote(data) {
    return axiosService.put(`/api/externalitem/updatenote`, data);
  }

  updateCustomerNote(data) {
    return axiosService.put(`/api/externalitem/updatecustomernote`, data);
  }
}

export default new ExternalItemService();

import axios from "../axios";

class GiftCollectionService {
  getGiftCollectionAll(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`giftcollections/${pageNo}?${params}`);
  }

  createGiftCollection(data) {
    return axios.post("/giftcollections", data);
  }

  getGiftCollectionById(id) {
    return axios.get(`/giftcollections/getById/${id}`);
  }

  getGiftCollectionList() {
    return axios.get(`/giftcollections`);
  }

  deleteGiftCollection(id) {
    return axios.delete(`/giftcollections/${id}`);
  }

  updateGiftCollection(id, data) {
    return axios.put(`/giftcollections/${id}`, data);
  }

  toggleActivation(id) {
    return axios.put(`/giftcollections/toggleactivation/${id}`);
  }
}

export default new GiftCollectionService();

import * as React from "react";
import { Card, Grid } from "@mui/material";
import { getDetails } from "src/actions/orders";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import Details from "./details";
import OrderDetailsTable from "./table";
import { getInternationalShippingCompanyList } from "src/actions/internationalShippingCompany";
import { getLocalShippingCompanyList } from "src/actions/localShippingCompany";

export default function OrderDetails() {
  const dispatch = useDispatch();

  const orderId = window.location.pathname.replace("/app/order/", "");

  const [refresh, setRefresh] = useState(false);
  const [selectedRows, setSelectRows] = useState([]);

  useEffect(() => {
    dispatch(getDetails(orderId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  useEffect(() => {
    dispatch(getInternationalShippingCompanyList());
    dispatch(getLocalShippingCompanyList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Card>
      <Grid container spacing={2}>
        <Grid item lg={9.5} md={12} xl={9.5} xs={12} order={{ xs: 2 }}>
          <OrderDetailsTable
            refresh={() => setRefresh(!refresh)}
            onSelectedRows={(rows) => setSelectRows(rows)}
            selectedRows={selectedRows}
            onSuccess={() => {
              setSelectRows([]);
            }}
          />
        </Grid>
        <Grid item lg={2.5} md={12} xl={2.5} xs={12} order={{ lg: 2 }}>
          <Details
            refresh={() => setRefresh(!refresh)}
            onSelectedRows={(rows) => setSelectRows(rows)}
            selectedRows={selectedRows}
            onSuccess={() => {
              setSelectRows([]);
            }}
          />
        </Grid>
      </Grid>
    </Card>
  );
}

import React, { useState, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Tooltip,
  LinearProgress,
  Typography,
  Stack,
  Icon,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import moment from "moment";
import ShareCenterSearchFilter from "./searchFilter";
import {
  getAll,
  getById,
  openDialog,
  openNoteDialog,
  savePageNo,
  saveSearchBody,
} from "src/actions/externalItem";
import CustomSpinner from "src/components/CustomSpinner";
import { getListItemCancelReasons } from "src/actions/itemCancelReason";
import { useNavigate } from "react-router";
import InfoIcon from "@mui/icons-material/Info";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import ExternalItemStatusMenu from "./menu";
import { getByIdCart, openCartDialog } from "src/actions/cart";
import { FaCartPlus } from "react-icons/fa";
import { BiNotepad } from "react-icons/bi";
import ExternalItemNote from "../../form/note";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CartExternalItemForm from "src/pages/cross/cart/form/Form";
import useCanShow from "src/components/CanShow";
import UserTierMenu from "src/pages/cross/Users/userTierMenu";

export default function EXternalItemTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const itemDetailsRole = useCanShow("13-04");
  const noteRole = useCanShow("13-07");
  const userCartRole = useCanShow("06-09");
  const addToUserCartRole = useCanShow("17-03");

  const _ExternalItem = useSelector((state) => state.externalItem);
  const searchFilter = useSelector((state) => state.externalItem.searchBody);
  const PageNo = useSelector((state) => state.externalItem.pageNo);
  const reload = useSelector((state) => state.externalItem.reload);
  const _Cart = useSelector((state) => state.cart.openDialog);
  const itemReasons = useSelector(
    (state) => state.itemCancelReason.itemCancelReasonDataList
  );

  const [refresh, setRefresh] = useState(false);
  const [page, setPage] = useState(PageNo ? PageNo : 0);
  const [cartId, setCartId] = useState(null);

  useEffect(() => {
    dispatch(getAll(page + 1, searchFilter)).then((x) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFilter, page, refresh]);

  useEffect(() => {
    dispatch(getListItemCancelReasons());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(savePageNo(page));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (newPageSize) => {
    dispatch(saveSearchBody({ ...searchFilter, pageSize: newPageSize }));
    setPage(0);
  };

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "customer",
      headerName: "Customer Information",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="column" spacing={0.5}>
          <Typography variant="body" fontWeight="bold">
            {params.row.customer?.fullName}
          </Typography>
          <Typography variant="body">
            {params.row.customer?.phoneNumber}
          </Typography>
          <UserTierMenu
            userId={params.row?.customer?.id}
            tier={params.row?.customer?.customerTier}
            onSuccess={() => setRefresh(!refresh)}
          />
        </Stack>
      ),
    },
    {
      field: "url",
      headerName: "Item URL",
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.row.url}>
          <a href={params.row.url} target="_blank" rel="noreferrer">
            <Typography variant="body1" noWrap>
              Item URL
            </Typography>
          </a>
        </Tooltip>
      ),
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={<Typography>{params.row.description}</Typography>}
          aria-label="edit"
          placement="bottom-start"
        >
          <Typography variant="body" noWrap>
            {params.row.description}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 0.5,
      cellClassName: "quantity",
      headerAlign: "center",
      renderCell: (params) => (
        <Typography
          sx={{
            color: params.row.quantity > 1 ? "#1675e0" : null,
            fontWeight: params.row.quantity > 1 ? 600 : 300,
          }}
        >
          {params.row.quantity}
        </Typography>
      ),
    },
    {
      field: "statusString",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Stack direction="row" justifyContent="center" alignItems="center">
          <ExternalItemStatusMenu
            externalItemId={params.row.id}
            status={params.row.statusString}
            rejectReasonId={params.row.rejectReasonId}
            cartId={params.row.cartId}
            onSuccess={() => {
              if (params.row.status === 3)
                navigate(`/app/cart/${params.row.customer?.cartId}`);
              setRefresh(!refresh);
            }}
          />
          {params.row.rejectReasonId ? (
            <IconButton>
              <Tooltip
                title={
                  itemReasons?.filter(
                    (x) => x.id === params.row.rejectReasonId
                  )[0]
                    ? itemReasons?.filter(
                        (x) => x.id === params.row.rejectReasonId
                      )[0].nameEN
                    : null
                }
                aria-label="cancel"
              >
                <InfoIcon />
              </Tooltip>
            </IconButton>
          ) : null}
        </Stack>
      ),
    },
    {
      field: "reviewer",
      headerName: "Reviewer",
      flex: 1,
      renderCell: (params) => (
        <Stack>
          <Typography>{params.row.reviewer}</Typography>
          <Typography variant="caption">
            {params.row.reviewedAt
              ? moment(params.row.reviewedAt).format("yyyy-MM-DD / hh:mm a")
              : "---"}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "note",
      headerName: "Note",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={<Typography>{params.row.note}</Typography>}
          aria-label="edit"
          placement="bottom-start"
        >
          <Typography>{params.row.note}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "customerNote",
      headerName: "Customer Note",
      flex: 1,
      renderCell: (params) => (
        <Tooltip
          title={<Typography>{params.row.customerNote}</Typography>}
          aria-label="edit"
          placement="bottom-start"
        >
          <Typography>{params.row.customerNote}</Typography>
        </Tooltip>
      ),
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 0.7,
      renderCell: (params) => (
        <Stack>
          <Typography>
            {moment(params.row.createdAt).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="caption">
            {moment(params.row.createdAt).format("hh:mm a")}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "actions",
      type: "actions",
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Icon color="primary">visibility</Icon>}
          label="Item Details"
          hidden={!itemDetailsRole}
          showInMenu
          onClick={(e) => {
            dispatch(getById(params.row.id)).then((x) =>
              dispatch(openDialog())
            );
          }}
        />,
        <GridActionsCellItem
          icon={<BiNotepad size={22} />}
          label="Note"
          hidden={!noteRole}
          showInMenu
          onClick={(e) => {
            dispatch(openNoteDialog(params.row));
          }}
        />,
        <GridActionsCellItem
          icon={<ShoppingCartIcon color="warning" size={22} />}
          label="User Cart"
          hidden={!userCartRole}
          showInMenu
          onClick={(e) => {
            navigate(`/app/cart/${params.row.customer?.cartId}`);
          }}
        />,
        <GridActionsCellItem
          icon={<FaCartPlus color="secondary" size={22} />}
          label="Add To User Cart"
          hidden={!addToUserCartRole}
          showInMenu
          onClick={(e) => {
            dispatch(openCartDialog());
            setCartId(params.row.customer?.cartId);
            dispatch(getByIdCart(params.row));
          }}
        />,
        <GridActionsCellItem
          icon={<WhatsAppIcon color="success" size={22} />}
          label="Chat Via WhatsApp"
          showInMenu
          onClick={(e) => {
            window.open(
              `https://wa.me/+964${params.row.customer?.phoneNumber}`,
              "_blank"
            );
          }}
        />,
      ],
    },
  ];

  return (
    <Card>
      <ShareCenterSearchFilter pageNo={(newPage) => setPage(newPage)} />
      {_Cart && <CartExternalItemForm cartId={cartId} />}
      {_ExternalItem.noteDialog && <ExternalItemNote />}
      <PerfectScrollbar>
        <Box sx={{ margin: 2 }}>
          {!_ExternalItem.data ? (
            <CustomSpinner />
          ) : (
            <Box sx={{ height: { md: "calc(100vh - 275px)", xs: 1000 } }}>
              <DataGrid
                sx={{
                  ".highlight": {
                    bgcolor: "#bdbdbd",
                    "&:hover": {
                      bgcolor: "darkgrey",
                    },
                  },
                  ".quantity": {
                    justifyContent: "center",
                  },
                }}
                loading={reload}
                rows={_ExternalItem.data}
                getRowId={(params) => params.id}
                columns={columns}
                rowHeight={75}
                getRowClassName={(params) => {
                  return params.row.status === 3 || params.row.status === 4
                    ? "highlight"
                    : "";
                }}
                disableSelectionOnClick
                pagination
                pageSize={searchFilter.pageSize}
                page={page}
                onPageChange={(newPage) => handlePageChange(newPage)}
                onPageSizeChange={(newPageSize) =>
                  handleLimitChange(newPageSize)
                }
                rowCount={_ExternalItem.rowCount}
                paginationMode="server"
                rowsPerPageOptions={[15, 25, 75, 100]}
                components={{
                  LoadingOverlay: LinearProgress,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
              />
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
}

import { useEffect } from "react";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  addExternalItemsToCart,
  getCartById,
  getCartExternalItems,
  openCartExternalItemsDialog,
  saveCartExternalItemsPageNo,
  saveCartExternalItemsSearchBody,
} from "src/actions/cart";
import { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import CustomEmpty from "src/components/CustomEmpty";
import moment from "moment";
import { MdDateRange, MdProductionQuantityLimits } from "react-icons/md";
import { RxDimensions } from "react-icons/rx";
import { FaWeightHanging } from "react-icons/fa";
import { CgDanger } from "react-icons/cg";
import { VENDORS_LOGO } from "src/utils/common";
import RolePermission from "src/components/RolePermission";

const CartExternalItems = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const {
    externalItemsPageNo,
    externalItemsSearchBody,
    externalItems: { dialog, reload, data, rowCount },
  } = useSelector((state) => state.cart);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(externalItemsPageNo ?? 0);

  const handleSelectedItem = (data) => {
    setLoading(true);
    dispatch(
      addExternalItemsToCart({
        cartId: id ?? props.cartId,
        itemId: data,
      })
    ).then((x) => {
      // dispatch(openCartDialog());
      if (id) {
        dispatch(getCartById(id));
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    dispatch(getCartExternalItems(page + 1, externalItemsSearchBody));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    dispatch(saveCartExternalItemsPageNo(newPage));
  };

  const Vendor = ({ params }) => {
    return (
      <Stack>
        <img
          alt="vendor logo"
          src={
            params.vendorLogo
              ? params.vendorLogo
              : VENDORS_LOGO.filter((vendor) =>
                  vendor.name == params.vendorName ? vendor.path : null
                )[0]?.path
          }
          width={95}
        />
        {params.shippingOptions != null ? (
          <Chip
            variant="outlined"
            size="small"
            label={params.shippingOptions?.nameEn}
            color={
              params.shippingOptions?.nameEn === "Sea"
                ? "info"
                : params.shippingOptions?.nameEn === "Express"
                ? "warning"
                : "success"
            }
          />
        ) : null}
      </Stack>
    );
  };
  const Item = ({ params }) => {
    return (
      <Box>
        <Box sx={{ display: "flex" }}>
          <Box
            component="img"
            sx={{
              height: 130,
              maxWidth: 150,
            }}
            alt="itemImage"
            src={params.imageUrl}
          />
          <Box sx={{ marginLeft: "10px", width: "220px" }}>
            <Tooltip title={<Typography>{params.name}</Typography>}>
              <a
                rel="noreferrer"
                href={
                  params.productUrl.includes("shein")
                    ? params.productUrl.replace(/(https:\/\/\s*)m/g, "$1ar")
                    : params.productUrl
                }
                target="_blank"
              >
                <Typography variant="body1" noWrap>
                  {params.name}
                </Typography>
              </a>
            </Tooltip>
            <Typography>Price: {params.price} $</Typography>
            <Typography>
              <Tooltip title={params.size ? `size: ${params.size}` : ""}>
                <Typography variant="body1" noWrap>
                  {params.size ? `size: ${params.size}` : ""}
                </Typography>
              </Tooltip>
            </Typography>
            <Typography>
              <Tooltip title={params.color ? `color: ${params.color}` : ""}>
                <Typography variant="body1" noWrap>
                  {params.color ? `color: ${params.color}` : ""}
                </Typography>
              </Tooltip>
            </Typography>
            <Typography>
              <Tooltip title={params.style ? `style: ${params.style}` : ""}>
                <Typography variant="body1" noWrap>
                  {params.style ? `style: ${params.style}` : ""}
                </Typography>
              </Tooltip>
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  };
  const EditableValues = ({ params }) => {
    return (
      <Box sx={{ width: "100%" }}>
        <Typography
          sx={{ margin: "5px", fontSize: 13 }}
          color={params.quantity > 1 ? "#ff9800" : ""}
        >
          <Tooltip title="Quantity">
            <IconButton size="small" sx={{ p: 0, m: 0 }}>
              <MdProductionQuantityLimits />
            </IconButton>
          </Tooltip>
          {` : ${params.quantity}`}
        </Typography>
        <Typography sx={{ margin: "10px 5px", fontSize: 13 }}>
          <Tooltip title="Dimensions">
            <IconButton size="small" sx={{ p: 0, m: 0 }}>
              <RxDimensions />
            </IconButton>
          </Tooltip>
          {` : ${params.dimensions ? params.dimensions : "N/A"}`}
        </Typography>
        <Typography sx={{ margin: "10px 5px", fontSize: 13 }}>
          <Tooltip title="Weight">
            <IconButton size="small" sx={{ p: 0, m: 0 }}>
              <FaWeightHanging />
            </IconButton>
          </Tooltip>
          {` : ${params.weight}`}
        </Typography>
        <Typography sx={{ margin: "10px 5px", fontSize: 13 }}>
          <Tooltip title="Delivery Date">
            <IconButton size="small" sx={{ p: 0, m: 0 }}>
              <MdDateRange />
            </IconButton>
          </Tooltip>
          {` : ${
            params.deliveryDate
              ? moment(params.deliveryDate).format("yyyy-MM-DD")
              : "N/A"
          }`}
        </Typography>
        <Typography sx={{ margin: "10px 5px", fontSize: 13 }}>
          <Tooltip title="Dangerous Goods">
            <IconButton size="small" sx={{ p: 0, m: 0 }}>
              <CgDanger />
            </IconButton>
          </Tooltip>
          {` : ${params.dangerousGoods}`}
        </Typography>
      </Box>
    );
  };

  const columns = [
    {
      field: "#",
      headerName: "#",
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
    },
    {
      field: "vendor",
      headerName: "Vendor",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => <Vendor params={params.row} />,
    },
    {
      field: "item",
      headerName: "Item Details",
      flex: 2,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => <Item params={params.row} />,
    },
    {
      field: "EditableValues",
      headerName: "Editable Values",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => <EditableValues params={params.row} />,
    },
    {
      field: "shippingCost",
      headerName: "Shipping Cost ($)",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "totalAmount",
      headerName: "Total Amount ($)",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Stack>
          <Typography variant="body">
            {moment(params.row.createdAt).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="caption">
            {moment(params.row.createdAt).format("hh:mm a")}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "operations",
      headerName: "Operations",
      flex: 1,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <RolePermission permission={"06-28"}>
          <Button
            onClick={() => handleSelectedItem(params.row.id)}
            color="primary"
            variant="contained"
          >
            Add to Cart
          </Button>
        </RolePermission>
      ),
    },
  ];

  const handleDialog = () => {
    dispatch(openCartExternalItemsDialog());
  };

  return (
    <Dialog
      fullWidth
      open={dialog}
      onClose={handleDialog}
      maxWidth="lg"
      Draggable={true}
    >
      <DialogTitle>
        <Typography variant="h6">External Items</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1.5} marginTop={3}>
          <Grid item xs={12} md={3}>
            <TextField
              size="small"
              label="User Name"
              fullWidth
              value={externalItemsSearchBody.userName}
              onChange={(e) =>
                dispatch(
                  saveCartExternalItemsSearchBody({
                    ...externalItemsSearchBody,
                    userName: e.target.value,
                  })
                )
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(getCartExternalItems(1, externalItemsSearchBody));
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              size="small"
              label="Phone Number"
              fullWidth
              value={externalItemsSearchBody.phoneNumber}
              onChange={(e) => {
                const value = e.target.value
                  .replace(/^0+/, "")
                  .replace(/\D/g, "");
                dispatch(
                  saveCartExternalItemsSearchBody({
                    ...externalItemsSearchBody,
                    phoneNumber: value,
                  })
                );
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(getCartExternalItems(1, externalItemsSearchBody));
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              size="small"
              label="Item Name"
              fullWidth
              value={externalItemsSearchBody.name}
              onChange={(e) =>
                dispatch(
                  saveCartExternalItemsSearchBody({
                    ...externalItemsSearchBody,
                    name: e.target.value,
                  })
                )
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  dispatch(getCartExternalItems(1, externalItemsSearchBody));
                }
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <FormControlLabel
              control={
                <Switch
                  checked={externalItemsSearchBody.cartId}
                  onChange={(e) => {
                    dispatch(
                      saveCartExternalItemsSearchBody({
                        ...externalItemsSearchBody,
                        cartId: e.target.checked ? id : null,
                      })
                    );
                    dispatch(
                      getCartExternalItems(1, {
                        ...externalItemsSearchBody,
                        cartId: e.target.checked ? id : null,
                      })
                    );
                  }}
                />
              }
              label="Items added to this cart"
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <DataGrid
              sx={{
                ".highlight": {
                  bgcolor: "#fcb6b6",
                  "&:hover": {
                    bgcolor: "#faa2a2",
                  },
                },
              }}
              getRowClassName={(params) => {
                return params.row.prohibitedItemsReasonsDto ? "highlight" : "";
              }}
              autoHeight
              loading={reload || loading}
              page={externalItemsPageNo}
              rows={data ?? []}
              columns={columns}
              rowHeight={200}
              rowCount={rowCount}
              pageSize={externalItemsSearchBody.pageSize}
              disableSelectionOnClick
              pagination
              paginationMode="server"
              onPageChange={(newPage) => handlePageChange(newPage)}
              onPageSizeChange={(newPageSize) => {
                dispatch(
                  saveCartExternalItemsSearchBody({
                    ...externalItemsSearchBody,
                    pageSize: newPageSize,
                  })
                );
                dispatch(
                  getCartExternalItems(1, {
                    ...externalItemsSearchBody,
                    pageSize: newPageSize,
                  })
                );
              }}
              rowsPerPageOptions={[15, 25, 75, 100]}
              components={{
                LoadingOverlay: LinearProgress,
                NoRowsOverlay: CustomEmpty,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      {/* <DialogActions>
        <Stack
          sx={{ margin: 2 }}
          direction="row"
          spacing={2}
          justifyContent="end"
          alignItems="center"
        >
          <LoadingButton
            variant="contained"
            type="submit"
            color="primary"
            loading={loading}
            sx={{ width: 200 }}
          >
            Save
          </LoadingButton>
        </Stack>
      </DialogActions> */}
    </Dialog>
  );
};

export default CartExternalItems;

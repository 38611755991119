import {
  SHIPMENTS_RESET_FORM,
  SHIPMENTS_GET_BY_ID,
  SHIPMENTS_PAGE_NUMBER,
  SHIPMENTS_OPEN_DIALOG,
  SHIPMENTS_RELOAD,
  SHIPMENTS_GET_ALL,
  SHIPMENTS_SEARCH_BODY,
  ORDER_ITEM_EXIST_IN_SHIPMENT,
  PRINT_PACKING_LIST,
  PREPARING_SHIPMENTS,
  SHIPMENTS_TRACKING_NUMBERS,
} from "./type";

import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";
import ShipmentsService from "src/api/services/shipments.service";
import { getAllOrderItems, reloadOrderItem } from "src/actions/orderItems";

export const openDialog = (data) => (dispatch) => {
  dispatch({
    type: SHIPMENTS_OPEN_DIALOG,
    payload: data,
  });
};

export const resetForm = () => (dispatch) => {
  dispatch({
    type: SHIPMENTS_RESET_FORM,
  });
};

export const reloadShipments = () => (dispatch) => {
  dispatch({
    type: SHIPMENTS_RELOAD,
  });
};

export const saveSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SHIPMENTS_SEARCH_BODY,
    payload: data,
  });
};

export const savePageNo = (data) => (dispatch) => {
  dispatch({
    type: SHIPMENTS_PAGE_NUMBER,
    payload: data,
  });
};

export const orderItemsExistInShipments = (data) => (dispatch) => {
  dispatch({
    type: ORDER_ITEM_EXIST_IN_SHIPMENT,
    payload: data,
  });
};

export const getAllShipments = (pageNo, searchBody) => async (dispatch) => {
  try {
    dispatch(reloadShipments());
    const res = await ShipmentsService.getAll(pageNo, searchBody);
    dispatch({
      type: SHIPMENTS_GET_ALL,
      payload: res.data.data,
    });
    dispatch(reloadShipments());
    return Promise.resolve(res.data);
  } catch (err) {
    dispatch(reloadShipments());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateShipments =
  (id, data, pageType, pageNo, searchBody) => async (dispatch) => {
    try {
      const res = await ShipmentsService.update(id, data);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      if (pageType === "shipmentPage") {
        dispatch(getAllShipments(pageNo, searchBody));
      } else if (pageType === "orderItemPage") {
        dispatch(getAllOrderItems(pageNo, searchBody));
      }
      dispatch(openDialog());
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const updateShipmentStatus =
  (id, status, pageType, pageNo, searchBody) => async (dispatch) => {
    try {
      const res = await ShipmentsService.changeStatus(id, status);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      if (pageType === "shipmentPage") {
        dispatch(getAllShipments(pageNo, searchBody));
      } else if (pageType === "orderItemPage") {
        dispatch(getAllOrderItems(pageNo, searchBody));
      }
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const deleteShipments = (id, pageNo, searchBody) => async (dispatch) => {
  try {
    const res = await ShipmentsService.delete(id);
    dispatch(showSuccessSnackbar("Deleted successfully!"));
    dispatch(getAllShipments(pageNo, searchBody));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const deleteOrderItemFromShipment =
  (id, orderItemId) => async (dispatch) => {
    try {
      const res = await ShipmentsService.deleteOrderItemFromShipment(
        id,
        orderItemId
      );
      dispatch(showSuccessSnackbar("Deleted successfully!"));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getById = (id) => async (dispatch) => {
  try {
    dispatch(reloadOrderItem());
    dispatch(reloadShipments());
    const res = await ShipmentsService.getById(id);
    dispatch({
      type: SHIPMENTS_GET_BY_ID,
      payload: res.data.data,
    });
    dispatch(reloadOrderItem());
    dispatch(reloadShipments());
  } catch (err) {
    dispatch(reloadShipments());
    dispatch(reloadOrderItem());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getByShipmentNumber =
  (shipmentNumber, items) => async (dispatch) => {
    try {
      dispatch(reloadShipments());
      const res = await ShipmentsService.getByShipmentNumber(shipmentNumber);
      dispatch({
        type: SHIPMENTS_GET_BY_ID,
        payload: {
          ...res.data.data,
          orderItems: [
            ...res.data.data.orderItems,
            ...items.filter(
              (item) =>
                !res.data.data.orderItems.some(
                  (existingItem) => existingItem.id === item.id
                )
            ),
          ],
        },
      });
      dispatch(reloadShipments());
    } catch (err) {
      dispatch(reloadShipments());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const printPackingList = (id, isFetch) => async (dispatch) => {
  try {
    dispatch(reloadShipments());
    if (isFetch) {
      const res = await ShipmentsService.printPackingList(id);
      dispatch({
        type: PRINT_PACKING_LIST,
        payload: res.data.data,
      });
    } else {
      dispatch({
        type: PRINT_PACKING_LIST,
        payload: {},
      });
    }
    dispatch(reloadShipments());
  } catch (err) {
    dispatch(reloadShipments());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getPreparingShipments =
  (shipmentNumber, items) => async (dispatch) => {
    try {
      dispatch(reloadShipments());
      const res = await ShipmentsService.getPreparingShipments(shipmentNumber);
      dispatch({
        type: PREPARING_SHIPMENTS,
        payload: res.data.data,
      });
      // dispatch({
      //   type: SHIPMENTS_GET_BY_ID,
      //   payload: {
      //     ...res.data.data,
      //     orderItems: [
      //       ...res.data.data.orderItems,
      //       ...items.filter(
      //         (item) =>
      //           !res.data.data.orderItems.some(
      //             (existingItem) => existingItem.id === item.id
      //           )
      //       ),
      //     ],
      //   },
      // });
      dispatch(reloadShipments());
    } catch (err) {
      dispatch(reloadShipments());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const selectedShipment = (id, items) => async (dispatch) => {
  try {
    dispatch(reloadShipments());
    const res = await ShipmentsService.getById(id);
    dispatch({
      type: SHIPMENTS_GET_BY_ID,
      payload: {
        ...res.data.data,
        orderItems: [
          ...res.data.data.orderItems,
          ...items.filter(
            (item) =>
              !res.data.data.orderItems.some(
                (existingItem) => existingItem.id === item.id
              )
          ),
        ],
      },
    });
    dispatch(reloadShipments());
  } catch (err) {
    dispatch(reloadShipments());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getShipmentsTrackingNumbers =
  (shipmentId, isFetch) => async (dispatch) => {
    try {
      dispatch(reloadShipments());
      if (isFetch) {
        const res = await ShipmentsService.getShipmentsTrackingNumbers(shipmentId);
        dispatch({
          type: SHIPMENTS_TRACKING_NUMBERS,
          payload: res.data.data,
        });
      } else {
        dispatch({
          type: SHIPMENTS_TRACKING_NUMBERS,
          payload: {},
        });
      }
      dispatch(reloadShipments());
    } catch (err) {
      dispatch(reloadShipments());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

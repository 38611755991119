import ExternalItemService from "src/api/services/externalItem.service";
import helper from "src/utils/helper";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "./snackbarMessages";
import {
  OPEN_EXTERNAL_ITEM_DEALOG,
  GET_ALL_EXTERNAL_ITEM,
  SAVE_SEARCH_BODY_EXTERNAL_ITEM,
  GET_BY_ID_EXTERNAL_ITEM,
  RELOAD_EXTERNAL_ITEM,
  SAVE_SEARCH_PAGE_NO_EI,
  SAVE_CANCEL_REASON_ID,
  OPEN_EXTERNAL_ITEM_NOTE_DIALOG,
} from "./type";

export const openDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_EXTERNAL_ITEM_DEALOG,
  });
};

export const openNoteDialog = (data) => (dispatch) => {
  dispatch({
    type: OPEN_EXTERNAL_ITEM_NOTE_DIALOG,
    payload: data,
  });
};

export const reload = (data) => (dispatch) => {
  dispatch({
    type: RELOAD_EXTERNAL_ITEM,
    payload: data,
  });
};

export const saveCancelReasonId = (data) => (dispatch) => {
  dispatch({
    type: SAVE_CANCEL_REASON_ID,
    payload: data,
  });
};

export const getAll = (pageNo, data) => async (dispatch) => {
  try {
    dispatch(reload());
    const res = await ExternalItemService.getAll(pageNo, data);
    dispatch({
      type: GET_ALL_EXTERNAL_ITEM,
      payload: res.data.data,
    });
    dispatch(reload());
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const saveSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SEARCH_BODY_EXTERNAL_ITEM,
    payload: data,
  });
};

export const savePageNo = (data) => (dispatch) => {
  dispatch({
    type: SAVE_SEARCH_PAGE_NO_EI,
    payload: data,
  });
};

export const getById = (id) => async (dispatch) => {
  try {
    dispatch(reload());
    const res = await ExternalItemService.getById(id);
    dispatch({
      type: GET_BY_ID_EXTERNAL_ITEM,
      payload: res.data.data,
    });
    dispatch(reload());
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getByIdUpdate = (id) => async (dispatch) => {
  try {
    dispatch(reload());
    const res = await ExternalItemService.getById(id);
    dispatch({
      type: GET_BY_ID_EXTERNAL_ITEM,
      payload: res.data.data,
    });
    dispatch(reload());
    // return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const UpdateStatus = (data) => async (dispatch) => {
  try {
    const res = await ExternalItemService.updateStatus(data);
    dispatch(showSuccessSnackbar("Updated successfully"));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateNote = (data, pageNo, searchBody) => async (dispatch) => {
  try {
    const res = await ExternalItemService.updateNote(data);
    dispatch(showSuccessSnackbar("Updated successfully"));
    dispatch(openNoteDialog());
    dispatch(getAll(pageNo + 1, searchBody));
    return Promise.resolve(res.data);
  } catch (err) {
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateCustomerNote =
  (data, pageNo, searchBody) => async (dispatch) => {
    try {
      const res = await ExternalItemService.updateCustomerNote(data);
      dispatch(showSuccessSnackbar("Updated successfully"));
      dispatch(openNoteDialog());
      dispatch(getAll(pageNo + 1, searchBody));
      return Promise.resolve(res.data);
    } catch (err) {
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

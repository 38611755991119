import axios from "../axios";

class CartService {
  update(data) {
    return axios.put(`/cart/updateItem`, data);
  }

  getById(id) {
    return axios.get(`/cart/getById/${id}`);
  }

  getReviewByType(pageNo, { type, phoneNumber, vendorIds }) {
    var conditions = "";
    if (type) conditions += `&type=${type}`;
    if (phoneNumber) conditions += `&phoneNumber=${phoneNumber}`;
    if (vendorIds)
      vendorIds.forEach((element) => {
        conditions += `&vendorIds=${element}`;
      });
    return axios.get(`/cart/needreviews/${pageNo}?${conditions}`);
  }

  completeReviewById(cartId) {
    return axios.post(`/cart/completereview/${cartId}`);
  }

  clearGlobalCart(cartId) {
    return axios.delete(`/cart/clearcartbyadmin/${cartId}`);
  }

  deleteGlobalCartItem(itemCart, cartId) {
    return axios.delete(`/cart/removeitembyadmin/${itemCart}/${cartId}`);
  }

  updateProhibitedCartItems(data) {
    return axios.post(`/cart/updateprohibiteditemreason`, data);
  }

  getCartHistory(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        if (data[key] !== null && data[key] !== undefined && data[key] !== "") {
          params += `${key}=${data[key] ?? ""}&`;
        }
      });
    }
    return axios.get(`/cart/getcartreviewhistory/${pageNo}?${params}`);
  }

  getReviewersList() {
    return axios.get(`/cart/getallcartreviewer/`);
  }

  getCartExternalItems(pageNo, data) {
    let params = "";
    if (data) {
      Object.keys(data).forEach((key) => {
        params += `${key}=${data[key] == null ? "" : data[key]}&`;
      });
    }
    return axios.get(`/cart/externalitems/${pageNo}?${params}`);
  }

  addExternalItemsToCart(data) {
    return axios.post(`/cart/addexternalitemtocart/`, data);
  }
}

export default new CartService();

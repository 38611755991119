import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Chip, Icon } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateStatus } from "src/actions/orders";
import { order_statuses } from "src/utils/common";
import { showErrorSnackbar } from "src/actions/snackbarMessages";

export default function OrderStatusMenu({
  size = "small",
  onCancel = function () { },
  status = "",
  onSuccess = function () { },
  onError = function () { },
  orderId = null,
  orderData = null,
}) {
  const dispatch = useDispatch();

  const _UserInfo = useSelector((state) => state.users.userInfo);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [_status, setStatus] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  function updateOrderStatus(e) {
    // if (e.target.id === "2" && orderData?.remainingCODAmount > 0 && _UserInfo?.roles.includes("Finance")) {
    //   return dispatch(showErrorSnackbar("Delivered - الرجاء تحويل حالة الطلب الى"));
    // }
    dispatch(
      updateStatus({
        id: orderId,
        status: e.target.id,
      })
    ).then((res) => {
      onSuccess("Order status changed successfully");
    });
  }

  const getStatusIcon = (status) => {
    var color;
    status === "Pending"
      ? (color = "warning")
      : status === "Canceled" || status === "CanceledByCustomer"
        ? (color = "error")
        : status === "In Review"
          ? (color = "info")
          : status === "Delivered"
            ? (color = "success")
            : (color = "secondary");

    return (
      <Chip
        size="small"
        label={
          <span
            style={{
              whiteSpace: "normal",
              wordBreak: "break-word",
              textAlign: "center",
            }}
          >
            {status?.replace(/(?!(H))[A-Z]/g, " $&")}
          </span>
        }
        color={color}
        clickable
        onClick={handleClick}
        style={{
          height: "auto",
          padding: "4px",
          textAlign: "center",
        }}
      />
    );
  };

  return (
    <React.Fragment>
      {getStatusIcon(_status ? _status : status)}
      {/* {console.log(orderData)} */}
      <Menu
        anchorEl={anchorEl}
        open={
          !_UserInfo.roleOEndpoint.map((data) => data.Code).includes("22-06")
            ? false
            : open
        }
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {order_statuses.map((status) => (
          <MenuItem
            key={status.id}
            id={status.id}
            disabled={status.id === 3}
            onClick={
              status.name === "Canceled" ||
                status.name === "Canceled By Customer"
                ? onCancel
                : updateOrderStatus
            }
          >
            <ListItemIcon>
              <Icon>{status.icon}</Icon>
            </ListItemIcon>
            {status.name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}

import React, { useRef } from "react";
import {
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";
import { getShipmentsTrackingNumbers } from "src/actions/shipments";

const useStyles = makeStyles((theme) => ({
  mb: {
    marginBottom: "60px",
  },
  margin: {
    margin: "30px",
  },
  footerText: {
    fontSize: "18px",
    lineHeight: "0px",
  },
  footer: {
    borderBottomWidth: 3,
    backgroundColor: "black",
  },
  div: {
    fontSize: "26px",
  },
  h: {
    fontSize: "28px",
    fontWeight: "bold",
  },
  "@global": {
    "p, h4": {
      padding: "3px",
      color: "black",
    },
  },
  tableHeader: {
    textAlign: "center",
    backgroundColor: "#03a9f4",
    color: "white",
  },
}));

export default function ShipmentTrackingNumbers() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const printRef = useRef(null);

  const shipmentTrackingNumbersData = useSelector(
    (state) => state.shipments.shipmentsTrackingNumbers
  );

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true,
    pageStyle: `
    @media print {
      @page {
        size: A4;
        margin: 10;
      }
    }
  `,
  });

  return (
    <CustomDialog
      isOpen={shipmentTrackingNumbersData.dialog}
      handleClose={() => dispatch(getShipmentsTrackingNumbers({}))}
      title={""}
      maxWidth="lg"
      Draggable={true}
    >
      <div>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Button
            onClick={() => {
              handlePrint();
            }}
            color="primary"
            variant="contained"
            fullWidth
          >
            print
          </Button>
        </Stack>
        <div
          ref={printRef}
          style={{ padding: "15px", backgroundColor: "white" }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <Typography variant="h4">Shipping Number: {shipmentTrackingNumbersData.data?.shippingNumber} ({shipmentTrackingNumbersData.data?.statusName})</Typography>
                <Typography variant="h4">
                  Region: {shipmentTrackingNumbersData.data?.region}
                </Typography>
              </Stack>
              <Divider className={classes.footer} />
            </Grid>
            <Grid item xs={12}>
              {shipmentTrackingNumbersData.data?.internationalTrackingNumbers?.length > 0 ? (
                <Typography variant="h4">International Tracking Numbers ({shipmentTrackingNumbersData.data?.internationalTrackingNumbers?.length})</Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {shipmentTrackingNumbersData.data?.internationalTrackingNumbers?.length > 0 ? (
                <Grid container spacing={2}>
                  {shipmentTrackingNumbersData.data?.internationalTrackingNumbers.map((row, index) => (
                    <Grid item xs={12} sm={4} key={index}>
                      <Paper elevation={3} style={{ padding: "10px", textAlign: "center" }}>
                        <Typography variant="body2">{row}</Typography>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {shipmentTrackingNumbersData.data?.vendorTrackingNumbers?.length > 0 ? (
                <Typography variant="h4">Vendor Tracking Numbers ({shipmentTrackingNumbersData.data?.vendorTrackingNumbers?.length})</Typography>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {shipmentTrackingNumbersData.data?.vendorTrackingNumbers
                ?.length > 0 ? (
                <Grid container spacing={2}>
                  {shipmentTrackingNumbersData.data?.vendorTrackingNumbers
                    .map((row, index) => (
                      <Grid item xs={12} sm={4} key={index}>
                        <Paper elevation={3} style={{ padding: "10px", textAlign: "center" }}>
                          <Typography variant="body2">{row}</Typography>
                        </Paper>
                      </Grid>
                    ))}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </div>
      </div>
    </CustomDialog>
  );
}

import GiftCollectionService from "src/api/giftServices/collections.service";
import helper from "src/utils/helper";
import {
  GET_ALL_GIFT_COLLECTIONS,
  GET_BY_ID_GIFT_COLLECTIONS,
  GET_GIFT_COLLECTIONS_LIST,
  OPEN_GIFT_COLLECTIONS_DIALOG,
  RELOAD_GIFT_COLLECTIONS,
  RESET_GIFT_COLLECTIONS_FORM,
  SAVE_GIFT_COLLECTIONS_PAGE_NO,
  SAVE_GIFT_COLLECTIONS_SEARCH_BODY,
} from "src/actions/gifts/giftTypes";
import {
  openAlertDialog,
  showErrorSnackbar,
  showSuccessSnackbar,
} from "src/actions/snackbarMessages";

export const openGiftCollectionsDialog = () => (dispatch) => {
  dispatch({
    type: OPEN_GIFT_COLLECTIONS_DIALOG,
  });
};

export const saveGiftCollectionsPageNum = (data) => (dispatch) => {
  dispatch({
    type: SAVE_GIFT_COLLECTIONS_PAGE_NO,
    payload: data,
  });
};

export const saveGiftCollectionsSearchBody = (data) => (dispatch) => {
  dispatch({
    type: SAVE_GIFT_COLLECTIONS_SEARCH_BODY,
    payload: data,
  });
};

export const resetGiftCollectionsForm = () => (dispatch) => {
  dispatch({
    type: RESET_GIFT_COLLECTIONS_FORM,
  });
};

export const reloadGiftCollections = () => (dispatch) => {
  dispatch({
    type: RELOAD_GIFT_COLLECTIONS,
  });
};

export const createGiftCollections =
  (data, pageNo, searchBody, closeDialog) => async (dispatch) => {
    try {
      dispatch(reloadGiftCollections());
      const res = await GiftCollectionService.createGiftCollection(data);
      dispatch(showSuccessSnackbar("Created successfully"));
      dispatch(getAllGiftCollections(pageNo, searchBody));
      if (closeDialog) dispatch(openGiftCollectionsDialog());
      dispatch(reloadGiftCollections());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadGiftCollections());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const deleteGiftCollections =
  (id, pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadGiftCollections());
      const res = await GiftCollectionService.deleteGiftCollection(id);
      dispatch(showSuccessSnackbar("Gift collection deleted successfully!"));
      dispatch(getAllGiftCollections(pageNo, searchBody));
      dispatch(reloadGiftCollections());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadGiftCollections());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getGiftCollectionsById = (id, openDialog) => async (dispatch) => {
  try {
    dispatch(reloadGiftCollections());
    const res = await GiftCollectionService.getGiftCollectionById(id);
    dispatch({
      type: GET_BY_ID_GIFT_COLLECTIONS,
      payload: res.data.data,
    });
    if (openDialog) dispatch(openGiftCollectionsDialog());
    dispatch(reloadGiftCollections());
    // return Promise.resolve(res.data.data);
  } catch (err) {
    dispatch(reloadGiftCollections());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const getAllGiftCollections =
  (pageNO, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadGiftCollections());
      const res = await GiftCollectionService.getGiftCollectionAll(
        pageNO,
        searchBody
      );
      dispatch({
        type: GET_ALL_GIFT_COLLECTIONS,
        payload: res.data,
      });
      dispatch(reloadGiftCollections());
      // return Promise.resolve(res.data.data)
    } catch (err) {
      dispatch(reloadGiftCollections());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const getGiftCollectionsList = () => async (dispatch) => {
  try {
    dispatch(reloadGiftCollections());
    const res = await GiftCollectionService.getGiftCollectionList();
    dispatch({
      type: GET_GIFT_COLLECTIONS_LIST,
      payload: res.data.data,
    });
    dispatch(reloadGiftCollections());
    // return Promise.resolve(res.data.data)
  } catch (err) {
    dispatch(reloadGiftCollections());
    if (err.message.includes(401)) helper.logout();
    if (err.response.status === 401)
      dispatch(showErrorSnackbar("Token expire"));
    else if (err.response.status === 400)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 500)
      dispatch(showErrorSnackbar(err.response.data.message));
    else if (err.response.status === 403)
      dispatch(
        openAlertDialog("You do not have role permission for this action")
      );
    else dispatch(showErrorSnackbar("Network error"));
  }
};

export const updateGiftCollections =
  (id, data, pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadGiftCollections());
      const res = await GiftCollectionService.updateGiftCollection(id, data);
      dispatch(showSuccessSnackbar("Updated successfully!"));
      dispatch(getAllGiftCollections(pageNo, searchBody));
      dispatch(openGiftCollectionsDialog());
      dispatch(reloadGiftCollections());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadGiftCollections());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

export const giftCollectionsToggleActivation =
  (id, pageNo, searchBody) => async (dispatch) => {
    try {
      dispatch(reloadGiftCollections());
      const res = await GiftCollectionService.toggleActivation(id);
      dispatch(showSuccessSnackbar("Gift collection Updated Successfully!"));
      dispatch(getAllGiftCollections(pageNo, searchBody));
      dispatch(reloadGiftCollections());
      return Promise.resolve(res.data);
    } catch (err) {
      dispatch(reloadGiftCollections());
      if (err.message.includes(401)) helper.logout();
      if (err.response.status === 401)
        dispatch(showErrorSnackbar("Token expire"));
      else if (err.response.status === 400)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 500)
        dispatch(showErrorSnackbar(err.response.data.message));
      else if (err.response.status === 403)
        dispatch(
          openAlertDialog("You do not have role permission for this action")
        );
      else dispatch(showErrorSnackbar("Network error"));
    }
  };

import {
  Typography,
  Chip,
  Box,
  Stack,
  Grid,
  TextField,
  Button,
  LinearProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserPhoneCodeHistory,
  userPhoneCodeHistoryDialog,
} from "src/actions/users";
import { CustomDialog } from "src/components/CustomDialog";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { OPEN_PHONE_CODE_DATA } from "src/actions/type";

const UserPhoneCodeHistory = () => {
  const dispatch = useDispatch();

  const { data, dialog } = useSelector((state) => state.users.phoneCode);
  const loading = useSelector((state) => state.users.loading);
  const _UserInfo = useSelector((state) => state.users.userInfo);

  const { control, reset, handleSubmit } = useForm({
    defaultValues: {
      phoneNumber: "",
    },
  });

  useEffect(() => {
    reset({
      phoneNumber: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data) => {
    dispatch(getUserPhoneCodeHistory(data.phoneNumber)).then(() => { });
  };

  var columns = [
    {
      field: "id",
      headerName: "#",
      flex: 0.3,
      headerAlign: "center",
      renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
      hideable: true,
    },
    {
      field: "code",
      headerName: "Code",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "attemptCount",
      headerName: "Attempt Count",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "expiresAt",
      headerName: "Expires At",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Stack spacing={0} alignItems="center">
          <Typography>
            {moment(params.row.expiresAt).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="caption" color="red">
            {moment(params.row.expiresAt).format("HH:mm:ss a")}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "channel",
      headerName: "Channel",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "type",
      headerName: "Type",
      flex: 2,
      headerAlign: "center",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "isUsed",
      headerName: "Is Used",
      flex: 1,
      renderCell: (params) => (
        <Chip
          variant="outlined"
          label={params.row.isUsed ? "Yes" : "No"}
          color={params.row.isUsed ? "success" : "error"}
          size="small"
        />
      ),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Stack spacing={0} alignItems="center">
          <Typography>
            {moment(params.row.createdAt).format("YYYY-MM-DD")}
          </Typography>
          <Typography variant="caption">
            {moment(params.row.createdAt).format("HH:mm a")}
          </Typography>
        </Stack>
      ),
    },
  ];

  columns =
    _UserInfo.roles.includes("Admin") ||
      _UserInfo.roles.includes("Customer Service (Senior)") ||
      _UserInfo.roles.includes("SystemAdmin")
      ? columns
      : columns.filter((x) => x.field !== "code");

  return (
    <CustomDialog
      isOpen={dialog}
      handleClose={() => {
        dispatch(userPhoneCodeHistoryDialog());
        dispatch({
          type: OPEN_PHONE_CODE_DATA,
          payload: {},
        });
      }}
      maxWidth="lg"
      title={
        <Typography variant="body">
          Phone Code History For: ({data?.countryCode}) {data?.phoneNumber}{" "}
        </Typography>
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2} direction="row">
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    size="small"
                    label="Phone Number"
                    fullWidth
                    {...field}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value
                        .replace(/^0+/, "")
                        .replace(/[^0-9]/g, "");
                      field.onChange(sanitizedValue);
                    }}
                  />
                )}
              />
              <Button variant="contained" color="primary" type="submit">
                Search
              </Button>
            </Stack>
          </form>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: { md: "calc(100vh - 277px)", xs: 1000 } }}>
            <DataGrid
              sx={{
                "& .css-204u17-MuiDataGrid-main": {
                  paddingLeft: "15px",
                },
                "& .css-f3jnds-MuiDataGrid-columnHeaders": {
                  paddingLeft: "15px",
                },
                ".MuiDataGrid-cell": {
                  justifyContent: "center",
                },
              }}
              rows={
                data.info?.map((x, index) => {
                  return { ...x, id: index + 1 };
                }) || []
              }
              columns={columns}
              rowHeight={51}
              disableSelectionOnClick
              paginationMode="client"
              loading={loading}
              components={{
                LoadingOverlay: LinearProgress,
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </CustomDialog>
  );
};

export default UserPhoneCodeHistory;

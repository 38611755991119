import * as React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import {
  IconButton,
  Tooltip,
  Typography,
  LinearProgress,
  InputAdornment,
  Button,
  TextField,
  Chip,
  SpeedDialAction,
  SpeedDialIcon,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import moment from "moment";
import { openNoteHistoryDialog, updateOrder } from "src/actions/orders";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { Icon } from "@mui/material";
import SpeedDial from "@mui/material/SpeedDial";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "src/actions/snackbarMessages";
import { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { BiEdit, BiNotepad } from "react-icons/bi";
import { RxDimensions } from "react-icons/rx";
import { FaWeightHanging } from "react-icons/fa";
import { MdDateRange } from "react-icons/md";
import { CgDanger } from "react-icons/cg";
import { GoNote } from "react-icons/go";
import { MdProductionQuantityLimits } from "react-icons/md";
import CustomNoRowsOverlay from "src/components/CustomNoRowsOverlay";
import { VENDORS_LOGO } from "src/utils/common";
import CustomSpinner from "src/components/CustomSpinner";
import { Controller, useForm } from "react-hook-form";
import RolePermission from "src/components/RolePermission";
import CancelDialog from "src/pages/cross/orders/CancelDialog/CancelDialog";
import UpdateItemInfo from "src/pages/cross/orderItems/UpdateItem/UpdateItemInfo";
import OrderItemStatusMenu from "src/pages/cross/orderItems/Status/Manu";
import TimelineDialog from "src/pages/cross/orderItems/TimeLine/OrderItemTimeline";
import theme from "src/theme";
import { makeStyles } from "@mui/styles";
import { getById, openDialog } from "src/actions/shipments";
import ShipmentsForm from "src/pages/cross/shipments/form";
import { RiSwapBoxFill } from "react-icons/ri";
import { SHIPMENTS_GET_BY_ID } from "src/actions/type";
import NoteHistoryDialog from "src/pages/cross/orders/noteHistory/noteHistory";

const useStyles = makeStyles((theme) => ({
  staticTooltipLabel: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
  },
}));

export default function OrderDetailsTable(props) {
  const dispatch = useDispatch();

  const classes = useStyles();

  const _UserInfo = useSelector((state) => state.users.userInfo);
  const order = useSelector((state) => state.orders.orderDetails);
  const reload = useSelector((state) => state.orders.orderDetailsReload);
  const shipments = useSelector((state) => state.shipments);
  const orderHistoryDialog = useSelector(
    (state) => state.orders.orderHistoryDialog
  );

  const [openOrderItemTimeLine, setOpenOrderItemTimeLine] = useState(false);
  const [isOrder, setIsOrder] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [openCancelReasonsDialog, setOpenCancelReasonsDialog] = useState(false);
  const [openUpdateTrackAndInvoiceDialog, setOpenUpdateTrackAndInvoiceDialog] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState({
    id: null,
    weight: 0,
    dimensions: "",
    volume: 0,
    dangerousGoods: 0,
    shippingCost: 0,
    itemTrackingNumber: "",
    itemInvoiceNumber: "",
    orderItemId: null,
    purchasePrice: "",
  });
  const [openEditPriceDialog, setOpenEditPriceDialog] = useState(false);
  const [selectedDateRows, setSelectedDateRows] = useState([]);
  const [OpenMultiSelectDialog, setOpenMultiSelectDialog] = useState(false);
  const [itemStatus, setItemStatus] = useState("");
  const [selectedRowsData, setSelectedRowsData] = useState([]);


  const orderId = window.location.pathname.replace("/app/order/", "");

  const getItemById = (id) => {
    var item = order.items.filter((x) => x.id == id)[0];
    setSelectedItem({
      id: item.id,
      orderItemId: item.orderItemId,
      weight: item.weight,
      dimensions: item.dimensions,
      volume: 0,
      dangerousGoods: item.dangerousGoods,
      shippingCost: item.shippingCost,
      itemTrackingNumber: item.itemTrackingNumber,
      itemInvoiceNumber: item.itemInvoiceNumber,
      deliveryDate: item.deliveryDate,
      note: item.note,
      purchasePrice: item.purchasePrice,
    });
  };

  const Vendor = ({ params }) => {
    return (
      <Stack>
        <img
          alt="vendor logo"
          src={
            params.vendorLogo
              ? params.vendorLogo
              : VENDORS_LOGO.filter((vendor) =>
                vendor.name == params.vendorName ? vendor.path : null
              )[0]?.path
          }
          width={95}
        />
        {params.shippingOptionNameEn != null ? (
          <Chip
            variant="outlined"
            size="small"
            label={params.shippingOptionNameEn}
            color={
              params.shippingOptionNameEn === "Sea"
                ? "info"
                : params.shippingOptionNameEn === "Express"
                  ? "warning"
                  : "success"
            }
          />
        ) : null}
      </Stack>
    );
  };

  const Item = ({ item }) => {
    return (
      <Stack
        direction="column"
        spacing={1}
        justifyContent="center"
        alignItems="start"
        width="100%"
      >
        <Box
          sx={{ display: "flex", lineBreak: "anywhere", overflow: "visible" }}
        >
          <Box
            component="img"
            sx={{
              height: 100,
              width: 100,
              objectFit: "contain",
            }}
            alt="itemImage"
            src={item.imageUrl}
          />
          <Box sx={{ marginLeft: "10px", width: "220px" }}>
            <Tooltip title={<Typography>{item.name}</Typography>}>
              <a
                rel="noreferrer"
                href={
                  item.productUrl.includes("shein")
                    ? item.productUrl.replace(/(https:\/\/\s*)m/g, "$1ar")
                    : item.productUrl
                }
                target="_blank"
              >
                <Typography variant="body1" noWrap>
                  {item.name}
                </Typography>
              </a>
            </Tooltip>
            <Tooltip
              title={
                <Typography>
                  {item.addedToCartDate
                    ? `Cart Date: ${moment(item.addedToCartDate).format(
                      "yyyy-MM-DD / HH:mm"
                    )}`
                    : ""}
                </Typography>
              }
            >
              <Typography variant="body">
                {item.addedToCartDate
                  ? `Cart Date: ${moment(item.addedToCartDate).format(
                    "yyyy-MM-DD / HH:mm"
                  )}`
                  : ""}
              </Typography>
            </Tooltip>
            <Typography>Price: {item.price} $</Typography>
            {item.purchasePrice ? (
              <Typography color="primary">
                Purchase Price: {item.purchasePrice} $
              </Typography>
            ) : null}
            <Box>
              <Tooltip
                title={
                  <Typography>
                    {item.size ? `size: ${item.size}` : ""}
                  </Typography>
                }
              >
                <Typography variant="body1" noWrap>
                  {item.size ? `size: ${item.size}` : ""}
                </Typography>
              </Tooltip>
            </Box>
            <Tooltip
              title={
                <Typography>{item.color ? `${item.color}` : ""}</Typography>
              }
            >
              <Typography>
                {item.color ? `color: ${item.color}` : ""}
              </Typography>
            </Tooltip>
            {item.style ? (
              <Tooltip
                title={
                  <Typography>{item.style ? `${item.style}` : ""}</Typography>
                }
              >
                <Typography style={{ wordWrap: "break-word" }}>
                  {item.style}
                </Typography>
              </Tooltip>
            ) : null}
          </Box>
        </Box>
        <Stack
          direction="column"
          spacing={1}
          justifyContent="center"
          alignItems="start"
        >
          <OrderItemStatusMenu
            orderItemData={item}
            orderItemId={item.orderItemId}
            status={item.itemStatusString}
            onSuccess={(newStatus) => {
              props.refresh();
            }}
          />
          {item.cancelNote ? (
            <Tooltip
              title={item.cancelNote}
              aria-label="cancel"
              placement="bottom-start"
            >
              <Typography sx={{ lineBreak: "anywhere" }}>
                {item.cancelNote}
              </Typography>
            </Tooltip>
          ) : null}
        </Stack>
        <Box display="flex" width="100%">
          {item.internalStatuses?.map((status, index) => (
            <Chip
              key={index}
              variant="outlined"
              size="small"
              label={status.statusName}
              color="primary"
              sx={{ margin: "2px" }}
            />
          ))}
        </Box>
      </Stack>
    );
  };

  const EditableValues = ({ params }) => {
    return (
      <Stack
        justifyContent="start"
        spacing={1}
        sx={{ width: "100%", height: "100%", lineBreak: "anywhere" }}
      >
        <Typography
          sx={{
            fontSize: params.quantity > 1 ? 14 : 13,
            fontWeight: params.quantity > 1 ? "bold" : null,
          }}
          color={params.quantity > 1 ? "#ff0000" : ""}
        >
          <Tooltip title="Quantity">
            <IconButton size="small" sx={{ p: 0, m: 0 }}>
              <MdProductionQuantityLimits />
            </IconButton>
          </Tooltip>
          {` : ${params.quantity}`}
        </Typography>
        <Typography sx={{ fontSize: 13 }}>
          <Tooltip title="Dimensions">
            <IconButton size="small" sx={{ p: 0, m: 0 }}>
              <RxDimensions />
            </IconButton>
          </Tooltip>
          {` : ${params.dimensions ? params.dimensions : "N/A"}`}
        </Typography>
        <Typography sx={{ fontSize: 13 }}>
          <Tooltip title="Weight">
            <IconButton size="small" sx={{ p: 0, m: 0 }}>
              <FaWeightHanging />
            </IconButton>
          </Tooltip>
          {` : ${params.weight}`}
        </Typography>
        <Typography sx={{ fontSize: 13 }}>
          <Tooltip title="Delivery Date">
            <IconButton size="small" sx={{ p: 0, m: 0 }}>
              <MdDateRange />
            </IconButton>
          </Tooltip>
          {` : ${params.deliveryDate
            ? moment(params.deliveryDate).format("yyyy-MM-DD")
            : "N/A"
            }`}
        </Typography>
        <Typography sx={{ fontSize: 13 }}>
          <Tooltip title="Dangerous Goods">
            <IconButton size="small" sx={{ p: 0, m: 0 }}>
              <CgDanger />
            </IconButton>
          </Tooltip>
          {` : ${params.dangerousGoods}`}
        </Typography>
        <Typography
          sx={{ fontSize: 13 }}
          fontWeight={params.locker?.number ? "bold" : null}
          color="primary"
        >
          Locker: {params.locker?.number ?? "N/A"}
        </Typography>
      </Stack>
    );
  };

  const columns = [
    {
      field: "sequence",
      headerName: "#",
      headerAlign: "center",
      flex: 0.1,
      sortable: false,
      disableColumnMenu: true,
    },
    {
      field: "orderiiNumber",
      headerName: "Orderii No.",
      headerAlign: "center",
      flex: 0.3,
      renderCell: (params) => (
        <Stack direction="column" spacing={1} alignItems="center">
          <Typography>{params.row?.orderiiNumber}</Typography>
          {params.row?.isDamaged ? (
            <Chip
              size="small"
              label="Damaged"
              color="error"
              variant="outlined"
              sx={{ fontSize: "10px" }}
            />
          ) : null}
          {params.row?.isDangerousGoods ? (
            <Chip
              size="small"
              label="Dangerous Goods"
              color="error"
              variant="outlined"
              sx={{ fontSize: "10px" }}
            />
          ) : null}
        </Stack>
      ),
    },
    {
      field: "vendors",
      headerName: "Vendor",
      headerAlign: "center",
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => <Vendor params={params.row} />,
    },
    {
      field: "item",
      headerName: "Item Details",
      headerAlign: "center",
      width: 340,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => <Item item={params.row} />,
    },
    {
      field: "editableValues",
      headerName: "Editable Values",
      headerAlign: "center",
      flex: 0.44,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => <EditableValues params={params.row} />,
    },
    {
      field: "localPrice",
      headerName: "Local Price",
      headerAlign: "center",
      flex: 0.2,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: (params) => (
        <Typography
          sx={{
            whiteSpace: "pre-wrap",
            fontSize: "12px",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          Local Price
        </Typography>
      ),
      renderCell: (params) => (
        <Typography sx={{ whiteSpace: "pre-wrap" }}>
          {params.row.localPrice ? params.row.localPrice : "----"}
        </Typography>
      ),
    },
    {
      field: "shippingCost",
      headerName: "Shipping Cost",
      headerAlign: "center",
      flex: 0.2,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: (params) => (
        <Typography
          sx={{
            whiteSpace: "pre-wrap",
            fontSize: "12px",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          Shipping Cost
        </Typography>
      ),
      renderCell: (params) => (
        <Typography>{`${params.row.shippingCost} $`}</Typography>
      ),
    },
    {
      field: "internationalShippingCost",
      headerName: "International Shipping Cost",
      headerAlign: "center",
      flex: 0.2,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: (params) => (
        <Typography
          sx={{
            whiteSpace: "pre-wrap",
            fontSize: "12px",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          International Shipping Cost
        </Typography>
      ),
      renderCell: (params) => (
        <Typography>{`${params.row.internationalShippingCost} $`}</Typography>
      ),
    },
    {
      field: "totalAmount",
      headerName: "Total Amount",
      headerAlign: "center",
      flex: 0.2,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: (params) => (
        <Typography
          sx={{
            whiteSpace: "pre-wrap",
            fontSize: "12px",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          Total Amount
        </Typography>
      ),
      renderCell: (params) => (
        <Typography>
          {params.row.totalAmount ? `${params.row.totalAmount} $` : "----"}
        </Typography>
      ),
    },
    {
      field: "shippingNumber",
      headerName: "Shipment Number",
      headerAlign: "center",
      flex: 0.4,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Stack
          direction="row"
          spacing={0}
          justifyContent="center"
          alignItems="center"
        >
          <Button
            disabled={!params.row.shippingNumber?.shippingNumber}
            onClick={(e) => {
              navigator.clipboard.writeText(
                params.row.shippingNumber?.shippingNumber
              );
              dispatch(
                showSuccessSnackbar("Shipment number copied to clipboard")
              );
              dispatch(getById(params.row.shippingNumber?.id)).then(() => {
                dispatch(openDialog("updateShipment"));
              });
            }}
            sx={{ whiteSpace: "pre-wrap", lineBreak: "anywhere", fontSize: 12 }}
          >
            {params.row.shippingNumber
              ? params.row.shippingNumber?.shippingNumber
              : "----"}{" "}
          </Button>
        </Stack>
      ),
    },
    {
      field: "operations",
      headerName: "Operations",
      headerAlign: "center",
      flex: 0.3,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box>
          <Stack direction="row" spacing={0}>
            <RolePermission permission="23-02">
              <IconButton
                color="primary"
                aria-label="update tracking No and invoice"
                disabled={props.selectedRows.length > 0 ? true : false}
                onClick={(e) => {
                  setOpenUpdateTrackAndInvoiceDialog(true);
                  getItemById(params.row.id);
                  setItemStatus(params.row.itemStatusString);
                }}
              >
                <Tooltip
                  title="Update Tracking No and Invoice"
                  aria-label="cancel"
                >
                  <Icon>edit</Icon>
                </Tooltip>
              </IconButton>
            </RolePermission>
            <RolePermission permission="21-06">
              <IconButton
                color="error"
                disabled={
                  params.row.itemStatus == 8 ||
                    params.row.itemStatus == 9 ||
                    props.selectedRows.length > 0
                    ? true
                    : false
                }
                aria-label="cancel item"
                onClick={(e) => {
                  setIsOrder(false);
                  setOpenCancelReasonsDialog(true);
                  setSelectedItemId(params.row.id);
                }}
              >
                <Tooltip title="Cancel item" aria-label="cancel">
                  <CloseIcon />
                </Tooltip>
              </IconButton>
            </RolePermission>
          </Stack>
          <Stack direction="row" spacing={0}>
            <RolePermission permission="22-12">
              <IconButton
                disabled={props.selectedRows.length > 0}
                onClick={(e) => {
                  setSelectedItem(params.row);
                  setOpenOrderItemTimeLine(true);
                }}
              >
                <Tooltip title="Timeline">
                  <Icon>timeline</Icon>
                </Tooltip>
              </IconButton>
            </RolePermission>
            {params.row.note && (
              <Tooltip
                title={<Typography variant="h5">{params.row.note}</Typography>}
              >
                <IconButton color="success" size="small">
                  <GoNote size={25} />
                </IconButton>
              </Tooltip>
            )}
          </Stack>
          <Stack direction="row" spacing={0} sx={{ justifyContent: "center" }}>
            {params.row.cancelReasonId ? (
              <IconButton>
                <Tooltip title={params.row.cancelReasonEN} aria-label="cancel">
                  <InfoIcon color="error" />
                </Tooltip>
              </IconButton>
            ) : null}
          </Stack>
        </Box>
      ),
    },
  ];

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      notes: order?.notes ?? "",
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setValue("notes", order?.notes ? order?.notes : ""), [order]);

  const onSubmit = (data) => {
    dispatch(
      updateOrder({
        id: orderId,
        trackingNumber: order.trackingNumber,
        notes: data.notes,
      })
    ).then((res) => {
      props.refresh();
    });
  };

  return (
    <>
      {orderHistoryDialog && <NoteHistoryDialog />}
      {shipments.openDialog && (
        <ShipmentsForm
          handleClose={() => {
            props.onSelectedRows([]);
            setSelectedDateRows([]);
          }}
        />
      )}
      <TimelineDialog
        open={openOrderItemTimeLine}
        handleClose={() => setOpenOrderItemTimeLine(false)}
        orderItemId={selectedItem.orderItemId}
      />

      <CancelDialog
        open={openCancelReasonsDialog}
        handleClose={() => setOpenCancelReasonsDialog(false)}
        type={isOrder ? "order" : "item"}
        id={selectedItemId}
        orderId={orderId}
        onError={(msg) => dispatch(showErrorSnackbar(msg))}
        onSuccess={(msg) => {
          dispatch(showSuccessSnackbar(msg));
          props.refresh();
        }}
      />
      <UpdateItemInfo
        isOpen={openUpdateTrackAndInvoiceDialog}
        isOpenPurchasePrice={openEditPriceDialog}
        isOpenMultiItem={OpenMultiSelectDialog}
        selectedRows={props.selectedRows}
        selectedDateRows={selectedDateRows}
        selectedRowsData={selectedRowsData}
        handleClose={() => {
          setOpenUpdateTrackAndInvoiceDialog(false);
          setOpenEditPriceDialog(false);
          setOpenMultiSelectDialog(false);
          props.onSelectedRows([]);
          setSelectedDateRows([]);
        }}
        title="Update Tracking NO And invoice"
        onSuccess={(msg) => {
          dispatch(showSuccessSnackbar(msg));
          props.refresh();
        }}
        onFail={(msg) => {
          dispatch(showErrorSnackbar(msg));
          props.refresh();
        }}
        itemId={selectedItem.orderItemId}
        itemStatus={itemStatus}
        type="orderDetails"
      />
      <PerfectScrollbar>
        <Box sx={{ margin: 1 }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="notes"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  label="Notes"
                  fullWidth
                  multiline
                  maxRows={3}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BiNotepad size={25} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end" sx={{ marginY: 2 }}>
                        <Stack direction="row" spacing={1}>
                          <RolePermission permission="22-08">
                            <Button fullWidth variant="contained" type="submit">
                              Update
                            </Button>
                          </RolePermission>
                          <RolePermission permission="22-16">
                            <Button
                              variant="contained"
                              color="warning"
                              fullWidth
                              onClick={() => {
                                dispatch(
                                  openNoteHistoryDialog({
                                    id: orderId,
                                    type: "order",
                                  })
                                );
                              }}
                            >
                              History
                            </Button>
                          </RolePermission>
                        </Stack>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </form>
        </Box>
        <Box>
          <Box position="relative">
            <SpeedDial
              ariaLabel="SpeedDial tooltip example"
              sx={{ position: "absolute", bottom: -400, right: 0 }}
              icon={<SpeedDialIcon />}
              open={props.selectedRows.length > 0}
            >
              <SpeedDialAction
                hidden={
                  _UserInfo.roleOEndpoint
                    .map((data) => data.Code)
                    .includes("59-03")
                    ? false
                    : true
                }
                icon={<RiSwapBoxFill size={30} color="white" />}
                sx={{
                  "& .MuiSpeedDialAction-fab": {
                    width: "50px",
                    height: "50px",
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
                classes={classes}
                tooltipTitle={
                  <Stack
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    width={200}
                  >
                    <Typography variant="h6">
                      Add To Existed Shipment
                    </Typography>
                  </Stack>
                }
                tooltipOpen
                onClick={() => {
                  dispatch({
                    type: SHIPMENTS_GET_BY_ID,
                    payload: {
                      shippingNumber: "",
                      itemsCount: props.selectedRows.length,
                      region: "",
                      orderItems: order.items
                        .filter((x) =>
                          props.selectedRows.includes(x.orderItemId)
                        )
                        .map((item) => ({
                          ...item,
                          id: item.orderItemId,
                        })),
                    },
                  });
                  dispatch(openDialog("updateShipment"));
                }}
              />
              <SpeedDialAction
                icon={<BiEdit size={30} color="white" />}
                sx={{
                  "& .MuiSpeedDialAction-fab": {
                    width: "50px",
                    height: "50px",
                    backgroundColor: theme.palette.primary.main,
                  },
                }}
                classes={classes}
                tooltipTitle={
                  <Stack
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    width={200}
                  >
                    <Typography variant="h6">Update Order Items</Typography>
                  </Stack>
                }
                tooltipOpen
                onClick={() => {
                  setOpenMultiSelectDialog(true);
                }}
              />
            </SpeedDial>
          </Box>
          {!order ? (
            <CustomSpinner />
          ) : (
            <Box sx={{ height: { md: "calc(100vh - 280px)", xs: 1000 } }}>
              <DataGrid
                sx={{
                  ".MuiDataGrid-cell": {
                    justifyContent: "center",
                  },
                  ".highlight": {
                    bgcolor: "#bdbdbd",
                    "&:hover": {
                      bgcolor: "darkgrey",
                    },
                  },
                }}
                rows={order.items.map((row, index) => {
                  return { ...row, sequence: index + 1 };
                })}
                loading={reload}
                getRowId={(row) => row.orderItemId}
                columns={columns}
                rowHeight={
                  order.items.filter((x) => x.internalStatuses.length > 0)
                    .length > 0
                    ? 220
                    : 200
                }
                getRowClassName={(params) => {
                  return params.row.itemStatus == 8 ||
                    params.row.itemStatus == 9
                    ? "highlight"
                    : "";
                }}
                disableSelectionOnClick
                checkboxSelection
                onSelectionModelChange={(selection) => {
                  props.onSelectedRows(selection);
                  setSelectedRowsData(selection.map(
                    (selectedId) =>
                      order.items?.find((row) => row.orderItemId === selectedId)

                  ));
                  const selectedPricesArray = selection.map(
                    (selectedId) =>
                      order.items?.find((row) => row.orderItemId === selectedId)
                        ?.orderItemId
                  );
                  setSelectedDateRows(selectedPricesArray);
                }}
                isRowSelectable={(params) =>
                  (params.row.itemStatus !== 8) & (params.row.itemStatus !== 9)
                }
                selectionModel={props.selectedRows}
                pagination
                rowsPerPageOptions={[100]}
                paginationMode="client"
                components={{
                  LoadingOverlay: LinearProgress,
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
              />
            </Box>
          )}
        </Box>
      </PerfectScrollbar>
    </>
  );
}

import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Chip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { updateStatus } from "src/actions/orderItems";
import { item_statuses } from "src/utils/common";
import Icon from "@mui/material/Icon";
import { showErrorSnackbar } from "src/actions/snackbarMessages";

export default function OrderItemStatusMenu({
  size = "small",
  onCancel = function () {},
  status = "",
  onSuccess = function () {},
  onError = function () {},
  orderItemId = null,
  orderItemData = null,
}) {
  const _UserInfo = useSelector((state) => state.users.userInfo);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [_status, setStatus] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch = useDispatch();

  function updateOrderItemStatus(e) {
    var newStatus = e.target.id;
    dispatch(
      updateStatus({
        ids: [orderItemId],
        itemStatus: newStatus,
      })
    ).then((res) => {
      onSuccess(newStatus);
    });
  }

  const getStatusIcon = (status) => {
    var color;
    status === "Pending"
      ? (color = "warning")
      : status === "Canceled" || status === "Canceled by customer"
      ? (color = "error")
      : status === "In Baghdad Warehouse"
      ? (color = "info")
      : status === "Delivered"
      ? (color = "success")
      : (color = "secondary");

    return (
      <Chip
        size="small"
        label={status}
        color={color}
        clickable
        onClick={handleClick}
      />
    );
  };

  return (
    <React.Fragment>
      {getStatusIcon(_status ? _status : status)}
      <Menu
        anchorEl={anchorEl}
        open={
          _UserInfo.roleOEndpoint.map((data) => data.Code).includes("23-03")
            ? open
            : false
        }
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {item_statuses.map((status) => (
          <MenuItem
            key={status.id}
            id={status.id}
            onClick={
              updateOrderItemStatus
              // status.name == "Canceled" ? onCancel : updateOrderItemStatus
            }
            disabled={status.id == 8 || status.id == 9}
          >
            <ListItemIcon>
              <Icon>{status.icon}</Icon>
            </ListItemIcon>
            {status.name}
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  );
}

import React, { useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useReactToPrint } from "react-to-print";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import Barcode from "react-barcode";
import moment from "moment";
import Logo from "src/components/Logo";
import { useSelector } from "react-redux";
import { CustomDialog } from "src/components/CustomDialog";

const useStyles = makeStyles((theme) => ({
  mb: {
    marginBottom: "60px",
  },
  margin: {
    margin: "30px",
  },
  footerText: {
    fontSize: "18px",
    lineHeight: "0px",
  },
  footer: {
    borderBottomWidth: 3,
    backgroundColor: "black",
  },
  div: {
    fontSize: "26px",
  },
  h: {
    fontSize: "28px",
    fontWeight: "bold",
  },
  "@global": {
    "p, h4": {
      padding: "3px",
      color: "black",
    },
  },
  tableHeader: {
    textAlign: "center",
    backgroundColor: "#03a9f4",
    color: "white",
  },
}));

export default function GenerateLabel({ data = {} }) {
  const classes = useStyles();
  const printRef = useRef(null);

  const exchangeRate = useSelector((state) => state.notification.exchangeRate);

  const [showAdditionalFees, setShowAdditionalFees] = useState(true);
  const [openNoteDialog, setOpenNoteDialog] = useState(true);
  const [noteDialogText, setNoteDialogText] = useState("");

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    copyStyles: true,
    pageStyle: `@media print {
          @page {
            size: A4;
            margin: 10;
          }
        }`,
  });

  // useEffect(() => {
  //   handlePrint();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  return (
    <div>
      <CustomDialog
        isOpen={openNoteDialog}
        handleClose={() => setOpenNoteDialog(false)}
        title={""}
        maxWidth="sm"
        Draggable={true}
        dir="rtl"
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">
              - الرجاء كتابة الملاحظات بالعربي الفصيح
            </Typography>
            <Typography variant="h4">- عدم الكتابة بخط اليد</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              label="Notes"
              value={noteDialogText}
              inputProps={{ maxLength: 80 }}
              onChange={(e) => {
                const lineCount = (e.target.value.match(/\n/g) || []).length;
                if (lineCount < 2) {
                  setNoteDialogText(e.target.value);
                }
              }}
              helperText={`${noteDialogText.length}/80`}
            />
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} sx={{ direction: "ltr" }}>
              <Button
                onClick={() => {
                  setOpenNoteDialog(false);
                }}
                color="primary"
                variant="contained"
                fullWidth
              >
                Done
              </Button>
              <Button
                onClick={() => {
                  setNoteDialogText("");
                }}
                color="warning"
                variant="contained"
                fullWidth
              >
                clear text
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </CustomDialog>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <Button
            onClick={() => {
              handlePrint();
            }}
            color="primary"
            variant="contained"
            fullWidth
          >
            print
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <Button
            onClick={() => {
              setOpenNoteDialog(true);
            }}
            color="warning"
            variant="contained"
            fullWidth
          >
            Add Notes
          </Button>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={showAdditionalFees}
                onClick={(event) => setShowAdditionalFees(event.target.checked)}
                size="small"
              />
            }
            label="Show Additional Fees"
          />
        </Grid>
      </Grid>
      <div ref={printRef} style={{ padding: "15px", paddingLeft: "80px" }}>
        <Box
          sx={{
            position: "relative",
            height: "152mm",
            width: "115mm",
          }}
        >
          <Stack direction="column">
            <Box padding={2}>
              <Stack direction="column">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="start"
                >
                  <Stack direction="column">
                    <Box paddingBottom={2}>
                      <Logo
                        altSrc={
                          "/static/images/logo/logo_orderii_dual_black.png"
                        }
                        style={{ width: "180px", float: "left" }}
                      />
                    </Box>
                    <Typography
                      className={classes.h}
                      variant="h4"
                      lineHeight={1}
                    >
                      Iraq Address
                    </Typography>
                    <Typography className={classes.div} lineHeight={1}>
                      Baghdad, Iraq
                    </Typography>
                    <Typography className={classes.div} lineHeight={1}>
                      AL-Jamiea
                    </Typography>
                    <Typography className={classes.div} lineHeight={1}>
                      (+964) 784 319 6472
                    </Typography>
                  </Stack>
                  <img
                    alt="QR Code"
                    src="/static/images/QR.png"
                    height="120px"
                  />
                </Stack>
              </Stack>
            </Box>
            <Divider className={classes.footer} />
            <Stack direction="column" padding={2} sx={{ direction: "rtl" }}>
              <Typography className={classes.h} variant="h4">
                الاسم: {data.customer.fullName}
              </Typography>
              <Stack direction="row" justifyContent="space-between">
                <Typography className={classes.div} paddingLeft={2}>
                  المحافظة: {data.customer.address.split(" - ")[1] ?? ""}
                </Typography>
                <Typography className={classes.div} paddingLeft={2}>
                  المدينة: {data.customer.address.split(" - ")[0] ?? ""}
                </Typography>
              </Stack>
              <Typography className={classes.div} paddingLeft={2}>
                رقم الهاتف: {"0" + data.customer.phoneNumber}
              </Typography>
              {/* <Typography className={classes.div} paddingLeft={2}>
                {`كلفة التوصيل: ${
                  data.toBeDelivered
                    ? "تم دفع مبلغ التوصيل"
                    : "لم يتم دفع مبلغ التوصيل"
                }`}
              </Typography> */}
              {data?.isCod ? (
                <Stack direction="row" alignItems="center" sx={{ mt: 1 }}>
                  <Typography className={classes.h} variant="h4">
                    طريقة الدفع:
                  </Typography>
                  <Typography className={classes.div}>
                    دفع عند الاستلام
                  </Typography>
                </Stack>
              ) : null}
              {data?.isCod && (
                <Stack direction="row" alignItems="center">
                  <Typography className={classes.h} variant="h4">
                    المبلغ المتبقي:
                  </Typography>
                  <Typography className={classes.div}>
                    {(
                      data?.remainingCODAmount * exchangeRate
                    ).toLocaleString() + " IQD"}
                  </Typography>
                </Stack>
              )}
              {showAdditionalFees && data?.orderAdditionalFees?.total > 0 ? (
                <Stack direction="row" alignItems="center">
                  <Typography className={classes.h} variant="h4">
                    مبالغ اضافية:
                  </Typography>
                  <Typography className={classes.div}>
                    {(
                      data?.orderAdditionalFees?.total * exchangeRate
                    )?.toLocaleString()}{" "}
                    IQD
                  </Typography>
                </Stack>
              ) : null}
              {(showAdditionalFees && data?.orderAdditionalFees?.total > 0) ||
              (data?.isCod && data?.remainingCODAmount > 0) ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ border: "1px solid #000", borderRadius: "8px" }}
                >
                  <Typography className={classes.h} variant="h4">
                    المجموع الكلي:
                  </Typography>
                  <Typography className={classes.div}>
                    {(
                      (data?.remainingCODAmount +
                        (showAdditionalFees
                          ? data?.orderAdditionalFees?.total
                          : 0)) *
                      exchangeRate
                    )?.toLocaleString()}{" "}
                    IQD
                  </Typography>
                </Stack>
              ) : null}
              <Typography className={classes.div} paddingLeft={2}>
                التاريخ: {moment(new Date()).format("YYYY-MM-DD")}
              </Typography>
              {noteDialogText.length > 0 ? (
                <Typography
                  className={classes.div}
                  paddingLeft={2}
                  style={{ wordWrap: "break-word", fontWeight: "bold" }}
                >
                  الملاحظات: {noteDialogText}
                </Typography>
              ) : null}
              <Divider className={classes.footer} />
            </Stack>
          </Stack>
          <Stack direction="column" justifyContent="center" alignItems="center">
            <Typography
              className={classes.div}
              sx={{ fontWeight: "bold", lineHeight: 1 }}
            >
              رقم الطلب
            </Typography>
            <Typography className={classes.div} lineHeight={1}>
              {data.number}
            </Typography>
            <Barcode height={40} displayValue={false} value={data.number} />
          </Stack>
          <Stack
            direction="column"
            sx={{
              position: "relative",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "black",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <Typography variant="subtitle2" color="white">
              www.orderii.co
            </Typography>
          </Stack>
        </Box>
      </div>
    </div>
  );
}

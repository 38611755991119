import { Helmet } from "react-helmet";
import { Box, Card, CardHeader, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import ShipmentsForm from "../../form";
import { FaBoxOpen } from "react-icons/fa";
import ExistInShipmentsForm from "src/pages/cross/shipments/form/existInShipments";
import PrintShipmentsPackingList from "src/pages/cross/shipments/components/printPackingList";
import ShipmentTrackingNumbers from "src/pages/cross/shipments/components/shipmentTrackingNumbers";

const ShipmentsHeader = () => {
  const _Shipments = useSelector((state) => state.shipments.openDialog);
  const _ExistInShipmentsForm = useSelector(
    (state) => state.shipments.OrderItemsExistInShipments
  );
  const _PrintPackingList = useSelector(
    (state) => state.shipments.printPackingList
  );
  const _ShipmentsTrackingNumbers = useSelector(
    (state) => state.shipments.shipmentsTrackingNumbers
  );

  return (
    <>
      <Helmet>
        <title>Shipments | Orderii</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
        }}
      >
        <Card>
          <CardHeader
            title={
              <Typography variant="h4" component="div">
                Shipments
              </Typography>
            }
            avatar={<FaBoxOpen size={20} />}
          />
          {_Shipments ? <ShipmentsForm /> : null}
          {_ExistInShipmentsForm.dialog ? <ExistInShipmentsForm /> : null}
          {_PrintPackingList.dialog ? <PrintShipmentsPackingList /> : null}
          {_ShipmentsTrackingNumbers.dialog ? <ShipmentTrackingNumbers /> : null}
        </Card>
      </Box>
    </>
  );
};

export default ShipmentsHeader;
